.rewards-action-modal {
  background-color: $Brand-Black;
  color: $white;
  width: 100%;
  height: 100%;
  position: relative;

  @media #{$breakpoint-above-mobile} {
    max-width: 580px;
    margin: 80px auto 0;
  }

  &--container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-max + 1;
    background-color: #202124a6;
  }

  &--title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;

    &-img {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      @media #{$breakpoint-above-mobile} {
        margin-right: 20px;
      }
    }

    &-text {
      font-family: $National2Condensed;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      margin: 0;
      text-transform: uppercase;

      @media #{$breakpoint-above-mobile} {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  &--close-btn {
    position: absolute;
    width: 16px;
    height: 16px;
    background: transparent;
    border: none;
    padding: 0;
    top: 20px;
    right: 20px;

    &-icon {
      width: 16px;
      height: 16px;
      vertical-align: top;
    }
  }

  &--content {
    padding: 16px;
  }
}
