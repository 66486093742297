.coupon-list {
  margin-bottom: 64px;
  padding: 0 16px;

  @media #{$breakpoint-above-tablet} {
    padding: 0;
  }

  &.horizontal {
    .coupon-wrapper.horizontal {
      flex-wrap: nowrap;
      width: auto;
      overflow-x: auto;
      padding-bottom: 48px;
    }
    .coupons {
      flex-wrap: nowrap;
    }
    .coupon {
      flex-basis: 43%;
      max-width: 43%;
      min-width: 43%;
      opacity: 1;
      -webkit-transition: opacity 0.5s ease-in-out;
      -moz-transition: opacity 0.5s ease-in-out;
      transition: opacity 0.5s ease-in-out;

      &.faded {
        opacity: 0.4;
      }

      @media #{$breakpoint-above-tablet} {
        flex-basis: 22%;
        max-width: 22%;
        min-width: 22%;
      }
    }
  }

  .coupons {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 32px;
    align-items: stretch;
    justify-content: flex-start;

    @media #{$breakpoint-above-tablet} {
      gap: 24px;
    }
  }

  .coupon {
    flex-grow: 2;
    flex-basis: 47%;
    max-width: 47%;
    position: relative;

    @media #{$breakpoint-above-tablet} {
      flex-basis: 23%;
      max-width: 23%;
    }

    &--wrapper {
      background: transparent;
      border: 0;
      cursor: pointer;
      height: 100%;
      padding: 0;
      text-align: left;
      width: 100%;
    }

    &--image {
      aspect-ratio: 4 / 3;
      margin-bottom: -72px;
      position: relative;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &--info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      color: $Brand-Black;
      border-radius: 0 0 8px 8px;
      padding: 80px 8px 16px;
      height: calc(100% - 80px);

      @media #{$breakpoint-above-mobile} {
        height: calc(100% - 175px);
      }

      @media #{$breakpoint-above-tablet} {
        height: calc(100% - 125px);
        padding: 80px 24px 24px;
      }
    }

    &--background {
      background-color: $white;
      border-radius: 0 0 8px 8px;
      padding: 0 24px 24px;
      box-shadow: $Brand-Red 6px 6px 0 0;
      height: calc(100% - 85px);
      width: 100%;
      z-index: 0;
      position: absolute;
      bottom: 0;

      @media #{$breakpoint-above-mobile} {
        height: calc(100% - 175px);
      }

      @media #{$breakpoint-above-tablet} {
        height: calc(100% - 125px);
      }
    }

    &--title {
      font-family: $National2Condensed;
      font-size: 20px;
      font-weight: $font-weight-bold;
      line-height: 1;
      overflow-wrap: break-word;
      text-transform: uppercase;

      @media #{$breakpoint-above-tablet} {
        font-size: 24px;
      }
    }

    &--price {
      font-family: $National2Regular;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.5;
    }

    &--strikethrough {
      color: $Brand-Red;
      padding-right: 8px;
      font-weight: 400;
    }

    &--enddate {
      font-family: $National2Regular;
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 0;
      font-weight: 400;
    }

    &--disposition {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-family: $National2Condensed;
      font-size: 14px;
      font-weight: $font-weight-bold;
      letter-spacing: 0.5px;
      line-height: 1;
      margin-bottom: 8px;
      text-transform: uppercase;

      @media #{$breakpoint-above-tablet} {
        font-size: 16px;
      }

      &_icon {
        display: inline-block;
        margin-right: 4px;
      }
    }

    &--banner {
      display: flex;
      justify-content: flex-end;
      text-align: right;
      position: absolute;
      z-index: 2;
      top: 44px;
      width: calc(100% - 16px);
      margin: 0 auto;

      @media #{$breakpoint-above-tablet} {
        width: calc(100% - 40px);
      }

      &_text {
        background-color: $Brand-Red;
        border-radius: 4px;
        color: $white;
        font-family: $National2Condensed;
        font-size: 14px;
        font-weight: $font-weight-bold;
        letter-spacing: 0.5px;
        line-height: 1;
        overflow-wrap: break-word;
        text-transform: uppercase;
        padding: 4px 8px;
        white-space: nowrap;
        overflow: hidden;

        @media #{$breakpoint-above-tablet} {
          font-size: 16px;
        }

        span {
          overflow: hidden;
          display: inline-block;
          width: 100%;
        }
      }
    }

    &--active-label {
      align-items: center;
      background: $light-green;
      border-radius: 4px;
      display: flex;
      font-family: $National2Regular;
      font-weight: $font-weight-500;
      font-size: 14px;
      line-height: 1.5;
      margin: 8px 0 0;
      padding: 4px 8px;
      width: max-content;

      &_icon {
        display: inline-block;
        height: 16px;
        margin-right: 3px;
        width: 16px;
      }
    }
  }
}
